<template>
  <div class="user_management">
    <el-row type="flex" justify="space-between">
      <el-input
        v-model="search_name"
        class="search_input"
        size="small"
        :placeholder="$t('personal.userGroupManagement.searchPlaceholder')"
        prefix-icon="el-icon-search"
        @input="searchName"
      >
      </el-input>
      <div>
        <el-button
          v-if="this.$store.getters.roles[0].name == 'SYSTEM_MANAGER'"
          class="add-button"
          icon="el-icon-plus"
          type="primary"
          @click="add_customer_dialog"
        >
          {{ $t("personal.userGroupManagement.newGroup") }}
        </el-button>
      </div>
    </el-row>
    <el-dialog
      :visible="tabVisible"
      class="dialog"
      :width="width"
      :close-on-click-modal="false"
      :show-close="true"
      @close="closeDialog"
    >
      <div slot="title"></div>
      <add-group
        v-if="tabVisible"
        ref="addUserDialog"
        :isEditText="tipText"
        :form-name="'addUserForm'"
        :form="form"
        :form-label="formLabel"
        :rules="rules"
        @submit="submit"
        @close="closeDialog"
      ></add-group>
    </el-dialog>
    <el-row>
      <user-group-manage-table
        @editGroup="handleChildEvent"
        @geteditmsg="handleGetgroup"
        ref="usertable"
      ></user-group-manage-table>
    </el-row>
  </div>
</template>

<script>
import { add_group, modify_single_group } from "@/network/addGroup/index.js";
import UserGroupManageTable from "./components/UserGroupManageTable.vue";
import AddGroup from "./components/AddGroup";
import ImportMultipleUser from "./components/ImportMultipleUser";
import { mapMutations, mapActions } from "vuex";
export default {
  inject: ["reload"],
  components: {
    UserGroupManageTable,
    AddGroup,
    ImportMultipleUser,
  },
  data() {
    return {
      width: "40%",
      tabVisible: false,
      search_name: "",
      tipText: "new",
      groupid: "",
      dialog_visible: false,
      optionObj: [],
      userIdentity: false,
      formLabel: [
        {
          label: this.$t("personal.userGroupManagement.addGroup.groupName"),
          key: "groupname",
          placeholder: this.$t(
            "personal.userGroupManagement.addGroup.placeholder.info1"
          ),
          type: "input",
        },
        {
          label: this.$t("personal.userGroupManagement.addGroup.groupMember"),
          key: "groupmember",
          placeholder: this.$t(
            "personal.userGroupManagement.addGroup.placeholder.info3"
          ),
          type: "select",
          options: this.optionObj,
        },
        {
          label: this.$t(
            "personal.userGroupManagement.addGroup.groupDescription"
          ),
          placeholder: this.$t(
            "personal.userGroupManagement.addGroup.placeholder.info3"
          ),
          key: "groupdes",
          type: "input",
        },
      ],
      form: {
        user_arr: [
          {
            groupname: "",
            groupmember: [],
            groupdes: "",
          },
        ],
      },
      rules: {
        groupname: [
          {
            required: true,
            message: this.$t(
              "personal.userGroupManagement.addGroup.placeholder.info1"
            ),
            trigger: "blur",
          },
        ],
        groupmember: [
          {
            required: true,
            message: this.$t(
              "personal.userGroupManagement.addGroup.placeholder.info1"
            ),
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.userIdentity = this.$store.getters.roles[0].name == "SYSTEM_MANAGER";
  },
  methods: {
    ...mapActions(["setuserGroup"]),
    ...mapMutations(["CHANGE_LOAD"]),
    setWidth(width) {
      this.width = width;
    },
    importMultipleUser() {
      this.$refs.importMultipleUserDialog.open();
    },
    closeDialog(val) {
      this.tabVisible = val;
      this.form.user_arr[0].groupname = "";
      this.form.user_arr[0].groupdes = "";
      this.form.user_arr[0].groupmember = [];
    },
    handleGetgroup(e) {
      this.groupid = e.groupId;
      this.form.user_arr[0].groupname = e.groupName;
      this.form.user_arr[0].groupdes = e.description;
      const arr = this.setNamelist(e.accountIdList);
      this.form.user_arr[0].groupmember = arr;
    },
    handleChildEvent() {
      this.tipText = "edit";
      this.tabVisible = true;
    },
    add_customer_dialog() {
      this.tipText = "new";
      this.tabVisible = true;
    },
    searchName(name) {
      this.$refs.usertable.searchbyName(name);
    },
    submit(new_users) {
      this.CHANGE_LOAD(true);
      const listnum = this.setNumlist(new_users.user_arr[0].groupmember);
      const params = {
        groupName: new_users.user_arr[0].groupname,
        accountIdList: listnum,
        description: new_users.user_arr[0].groupdes,
      };
      if (this.tipText == "new") {
        add_group(params).then((res) => {
          this.tabVisible = false;
          this.$refs.usertable.get_tenant_user_list(0, 10);
        });
      } else {
        modify_single_group(this.groupid, params).then((res) => {
          this.closeDialog();
          this.$refs.usertable.get_tenant_user_list(0, 10);
        });
      }
      this.setuserGroup();
    },
    setNamelist(arr) {
      let list = this.$store.state.store_tenant.user;
      let returnlist = [];
      for (const key in list) {
        arr.map((item) => {
          if (key == item) {
            returnlist.push(list[key].nickname);
          }
        });
      }
      return returnlist;
    },
    setNumlist(arr) {
      let list = this.$store.state.store_tenant.user;
      let returnlist = [];
      for (const key in list) {
        arr.map((item) => {
          if (list[key].nickname == item) {
            returnlist.push(key);
          }
        });
      }
      return returnlist;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-button {
  margin-right: 10px;
  font-size: 15px;
}
.dialog {
  ::v-deep .el-dialog__header {
    padding: 8px 0 !important;
  }
}
</style>
