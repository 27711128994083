<template>
  <div class="user-manage-table">
    <el-table
      height="70vh"
      :data="groupList"
      :empty-text="$t('placeholder.table1')"
      :header-cell-style="{
        fontWeight: 'normal',
        color: 'rgba(112, 112, 112, 1)',
      }"
      :cell-style="{ color: 'rgba(112, 112, 112, 1)' }"
    >
      <el-table-column
        :label="$t('personal.userGroupManagement.table.groupName')"
        align="left"
        header-align="left"
        width="150"
        prop="groupName"
      >
      </el-table-column>
      <el-table-column
        :label="$t('personal.userGroupManagement.table.member')"
        align="center"
        header-align="center"
        show-overflow-tooltip
        min-width="20%"
      >
        <template slot-scope="scope">
          <div class="avatar-with-name-center">
            <span v-for="(item, index) in scope.row.accountIdList" :key="index"
              >{{ searchNameFn(item)
              }}<span v-if="index != scope.row.accountIdList.length - 1"
                >,</span
              ></span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        :label="$t('personal.userGroupManagement.table.description')"
        align="left"
        header-align="left"
        min-width="15%"
      >
      </el-table-column>
      <el-table-column
        :label="$t('personal.userGroupManagement.table.operate')"
        width="100"
        v-if="this.$store.getters.roles[0].name == 'SYSTEM_MANAGER'"
      >
        <template slot-scope="scope">
          <el-dropdown>
            <el-button type="text" style="min-height: 16px">
              <i
                class="iconfont icon-a-216gengduo-shuxiang"
                style="color: rgb(48, 100, 143)"
              ></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                icon="el-icon-edit-outline"
                @click.native="handleEditUser(scope.row.groupId)"
              >
                {{ $t("btn.editBtn") }}
              </el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-delete"
                @click.native="handleDeleteUser(scope.row.groupId)"
              >
                {{ $t("btn.deleteBtn") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, prev, pager, next"
      :total="pageObj.total"
      :current-page="pageObj.currentPage"
      :page-size="10"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  delete_user_group,
  get_single_group,
  vague_search_group,
} from "@/network/addGroup/index.js";
import "@/components/svgIcon";
import { mapActions, mapMutations } from "vuex";

export default {
  components: {},
  props: ["searchlist"],
  data() {
    return {
      editUser: {
        user: {
          name: "",
          email: "",
        },
        roleId: "",
      },
      groupList: [],
      pageObj: {
        currentPage: 1,
        total: 0,
      },
    };
  },
  computed: {
    node_env() {
      return process.env.NODE_ENV;
    },
  },
  mounted() {
    this.get_tenant_user_list();
    this.CHANGE_LOAD(true);
    this.$emit("getPageall", this.pageObj.currentPage - 1);
  },
  methods: {
    ...mapActions(["getGroup", "setuserGroup"]),
    ...mapMutations(["CHANGE_LOAD"]),
    searchbyName(e) {
      const params = {
        page: 0,
        size: 10,
      };
      vague_search_group(params, e).then((res) => {
        this.groupList = res.content;
        this.pageObj.total = res.totalElements;
      });
    },
    handleCurrentChange(page) {
      this.$emit("getPageall", page - 1);
      this.pageObj.currentPage = page;
      this.get_tenant_user_list();
    },
    get_tenant_user_list() {
      const params = {
        page: this.pageObj.currentPage - 1,
        size: 10,
      };
      this.getGroup(params).then(() => {
        this.groupList = this.$store.state.group.grouplist.content;
        this.pageObj.total = this.$store.state.group.grouplist.totalElements;
      });
    },
    searchNameFn(num) {
      let list = this.$store.state.store_tenant.user;
      for (const key in list) {
        if (key == num) {
          return list[num].nickname;
        }
      }
    },
    handleEditUser(e) {
      this.$emit("editGroup");
      get_single_group(e).then((res) => {
        this.$emit("geteditmsg", res);
      });
    },
    handleDeleteUser(e) {
      if (this.$store.getters.roles[0].name == "SYSTEM_MANAGER") {
        this.$confirm(
          this.$t("personal.userGroupManagement.delete.tipText"),
          this.$t("personal.userGroupManagement.delete.tip"),
          {
            confirmButtonText: this.$t(
              "personal.userGroupManagement.delete.confirmDelete"
            ),
            cancelButtonText: this.$t(
              "personal.userGroupManagement.delete.confirmCanael"
            ),
            confirmButtonClass: "btnConfirm",
            cancelButtonClass: "btnCancel",
            type: "warning",
          }
        ).then(() => {
          this.CHANGE_LOAD(true);
          delete_user_group(e).then((res) => {
            this.get_tenant_user_list();
            this.$message({
              type: "success",
              message: this.$t(
                "personal.userGroupManagement.delete.deletetext"
              ),
            });
            this.setuserGroup();
          });
        });
      } else {
        this.$message.error(
          this.$t("personal.userGroupManagement.authorityTip")
        );
      }
    },
    handleEditUserChange(value) {
      this.userList[this.editIndex] = value;
    },
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
<style lang="scss" scoped>
.avatar-with-name-center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-manage-table {
  ::v-deep .el-table,
  ::v-deep .el-table tr,
  ::v-deep .el-table th {
    background-color: transparent;
  }
  ::v-deep .el-table {
    overflow: hidden;
  }
}
</style>
