import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 添加用户
export function add_user(user_arr) {
    const data = user_arr;
    return request({
        url: `/${serviceConfig['account-service']}/users`,
        method: 'post',
        data: data,
    })
}

// 验证邮箱是否已经注册
export function email_exist(user_info) {
    const data = { email: user_info }
    return request({
        url: `/${serviceConfig['account-service']}/check`,
        method: 'post',
        data: data,
    })
}

// 获取角色
export function get_roles() {
    return request({
        url: `/${serviceConfig['account-service']}/rolesList`,
        method: 'get',
    })
}