<template>
  <div class="add_user">
    <div class="tip">
      {{
        tip_text == "edit"
          ? $t("personal.userGroupManagement.editGroup")
          : $t("personal.userGroupManagement.newGroup")
      }}
    </div>
    <el-form :ref="formName" :model="form_update">
      <el-scrollbar
        style="height: 100%; padding-bottom: 20px; padding-right: 25px"
      >
        <div v-for="(temp, index) in form_update.user_arr" :key="index">
          <template v-for="(ele, i) in formLabel">
            <el-form-item
              :key="i"
              :prop="`user_arr.${index}.${ele.key}`"
              :rules="rules_update[ele.key]"
            >
              <span class="dialog-tip">
                {{ ele.label
                }}<span
                  style="color: red"
                  v-if="
                    ele.label !=
                    $t('personal.userGroupManagement.addGroup.groupDescription')
                  "
                  >*</span
                >
              </span>
              <el-select
                multiple
                v-if="ele.type == 'select'"
                v-model="temp[ele.key]"
                clearable
                filterable
              >
                <el-option
                  v-for="opt in ele.options"
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                >
                </el-option>
              </el-select>
              <el-input
                :type="ele.key == 'groupname' ? 'string' : 'textarea'"
                :placeholder="ele.placeholder"
                v-if="ele.type == 'input'"
                v-model="temp[ele.key]"
              ></el-input>
            </el-form-item>
          </template>
        </div>
      </el-scrollbar>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="closeDialog" size="small">{{
        $t("btn.cancelBtn")
      }}</el-button>
      <el-button size="small" class="confirm" type="primary" @click="save">{{
        $t("btn.confirmBtn")
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "560px",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    formLabel: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => {},
    },
    formName: {
      type: String,
      default: "",
    },
    isEditText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      groupName: "",
      userList: [],
      description: "",
      form_update: this.form,
      rules_update: this.rules,
      submiting: false,
      tip_text: this.isEditText,
      optionsAll: [],
    };
  },
  computed: {},
  mounted() {
    let list = this.$store.state.store_tenant.user;
    for (const key in list) {
      if (list[key].deleted == 0) {
        this.optionsAll.push({
          label: list[key].nickname,
          value: list[key].nickname,
        });
      }
    }
    this.formLabel[1].options = this.optionsAll;
  },

  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    save() {
      this.submiting = true;
      this.$nextTick(() => {
        this.submit();
      });
    },
    submit() {
      this.$refs[this.formName].validate((valid) => {
        if (valid) {
          this.$emit("submit", this.form_update);
        } else {
          this.submiting = false;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: rgba(56, 56, 56, 1);
}
.label {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;

  div {
    text-align: center;
    flex-grow: 1;
    span {
      color: red;
    }
  }
}

.add_user {
  .el-form {
    .el-scrollbar__view {
      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}

.addbtn {
  text-align: left;
  margin: 10px 0 10px 0;
}

.dialog-footer {
  text-align: right;
  margin-bottom: 20px;
}
.dialog-tip {
  display: flex;
}
</style>
